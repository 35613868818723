// react
import React from 'react';
import { PDFS } from '../shared/pdfs';

function ProductTabPdf(props) {
    const { pdfs } = props;

    const PdfList = pdfs.filter((e) => PDFS.includes(e.split('/').pop())).map((e, i) => <li key={i}>
        <a href={e}>
            <img src="images/download_pdf.png" height="80px" alt="PDF" />
            {e.split('/').splice(-1)}
        </a>
    </li>);

    return (
        <React.Fragment>
            <div>
                <ul>
                    {PdfList}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default ProductTabPdf;
